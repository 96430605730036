import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Title from "@lekoarts/gatsby-theme-minimal-blog/src/components/title";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Title text="Hi." mdxType="Title" />
    <p>{`I'm Michael - currently teaching "Defense against the Dark Arts" at Hogwarts, Great Britain. I recently published a book called Those nasty Dementors. It teaches everything you need to know about defending against Dementors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      